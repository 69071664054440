import React from 'react';
import './Footer.css';
function Footer()
{
    return (
        <footer>
            
            <p>&copy; Jazzy Jada</p>
        </footer>
    );
}

export default Footer;