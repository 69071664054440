// ProjectCarousel.js
import React from 'react';
import { useState,useEffect } from 'react';
import ThumbnailItem from './ThumbnailItem'; // Create this component for individual thumbnail items
import artData from '../../../data/artData';
import Project from "../Project/Project"
import "./ProjectCarousel.css"



function ProjectCarousel() {
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [projectsToShow, setProjectsToShow] = useState(4);

    useEffect(() => {
      setSelectedLanguage("All Languages");
    }, []);

    const handleThumbnailClick = (projectId) => {

        setSelectedProject(projectId);
      };

    const handleFilterChange = (event) =>
    {
      setSelectedLanguage(event.target.value);
      setProjectsToShow(4);
    }



        const selectedProjectDetails = selectedProject !== null ? artData.find(proj => proj.id === selectedProject)?.details : null;

  return (
    <div className="project-carousel">
        <div className="display-image neon-borde">
          {/* Display the giant image */}
          <img src={selectedProject !== null ? artData.find(proj => proj.id === selectedProject)?.imageSrc : 'placeholder_640_360.png'} alt="Giant Project Image" />
        </div>
        <br></br>
        <span className="thumbnail-container">
            {artData.map((project) => (
            <Project key={project.id}
            project={project}
            onClick={() => handleThumbnailClick(project.id)}
            />
            ))} 
        </span>
    </div>
  );
}

export default ProjectCarousel;
