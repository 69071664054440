import React from 'react';
import "./Project.css"

function Project({project,onClick}) {

  return (
    <span id="project" onClick = {onClick}>
        <img id="project-img" src={project.imageSrc} alt={project.name} />
      
      <div id="project-details">
        <h3>{project.name}</h3>
      </div>
    </span>
  );
}

export default Project;