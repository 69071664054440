import "./HomeSection.css"
import PortfolioList from '../../components/PortfolioList/PortfolioList';
import { useRef } from 'react';
import AboutSection from "../AboutSection/AboutSection";

function HomeSection({ref1}) {

  
  function scrollTo(ref) 
  {
    if (!ref.current) return;
    ref.current.scrollIntoView({ block:"start",behavior: "smooth" });
  }
    return (
      <div>
        <div id="home">
          <h1 id="aboutTitle">Jada Carter</h1>
          <p id= "intro"> 
          Illustrator | Animator | Character Designer
            </p>
            <h4>Work & Commissions Available</h4>
            <p>Availability is limited</p>
            <button id="project-button" onClick={() => scrollTo(ref1)}>Contact Me</button>
            <br></br>
        </div>
        <AboutSection imageName='/jada-carter-toonmeSquare.jpg' title='About Me' 
        description='This is blank text used to describe the owner of this page, 
        feel free to edit it whoever you please, more additions coming soon such as changing text style'
         isLeft={true}/>
        <PortfolioList/>
      </div>
      
    );
  }
  
  export default HomeSection;