import "./NavBar.css"
import { slide as Menu } from 'react-burger-menu'
import { useState } from "react";

function NavBar({ref0,ref1,ref2,ref3})
{
    const [changeColor, setChangeColor] = useState(false);
    const [blueOrRed, setBlueOrRed] = useState('Blue')

    function handleNeonColor()
    {
        //decider will be either 0 or 1
        let decider = Math.random() * 2;
        console.log(decider);
        if (decider >= 1)
        {
            setBlueOrRed('Blue');
        }
        else
        {
            setBlueOrRed('Red');
        }
    }

    const changeNavColor = () =>
        {
          if (window.scrollY > 300)
          {
             setChangeColor(true);
          }
          else
          {
             setChangeColor(false);
          }
        }

    window.addEventListener('scroll',changeNavColor)
 
    function scrollTo(ref) 
    {
      if (!ref.current) return;
      ref.current.scrollIntoView({ block:"start",behavior: "smooth" });
    }

    
    return (
        
        <div id="NavBar" className={changeColor ? 'scrolled' : 'unscrolled'}>  

            
            
            <span id="logo">
                <img src="profile_pic.png" id ="logo"></img>
            </span>
          
            <span className= "linkButtons"> 
                <button className = {blueOrRed === 'Blue' ? 'scrollButton-Blue' : 'scrollButton-Red'} onMouseOver={handleNeonColor} onClick={() => scrollTo(ref0)}>HOME</button>
                <button className = {blueOrRed === 'Blue' ? 'scrollButton-Blue' : 'scrollButton-Red'} onMouseOver={handleNeonColor}  onClick={() => scrollTo(ref1)}>PORTFOLIO</button>
                <button className = {blueOrRed === 'Blue' ? 'scrollButton-Blue' : 'scrollButton-Red'} onMouseOver={handleNeonColor}  onClick={() => scrollTo(ref2)}>ABOUT</button>
                <button className = {blueOrRed === 'Blue' ? 'scrollButton-Blue' : 'scrollButton-Red'} onMouseOver={handleNeonColor}  onClick={() => scrollTo(ref3)}>CONTACT</button>
                {/*}
                <a href="https://www.linkedin.com/in/chastin-taylor/" target ="_blank" rel="noreferrer"><img src="linkedin_icon.png" alt="linkedin" className="navImage"></img></a>
                <a href="https://github.com/ChastinT" target ="_blank" rel="noreferrer"><img src="github-mark-white.png" alt="github" className="navImage"></img></a>*/}
            </span>
            <span id="hamburger-menu">
               <Menu right>
               <button className = "scrollButton" onClick={() => scrollTo(ref0)}>Home</button>
               <button className = "scrollButton" onClick={() => scrollTo(ref1)}>About</button>
                <button className = "scrollButton" onClick={() => scrollTo(ref2)}>Portfolio</button>
                <button className = "scrollButton"onClick={() => scrollTo(ref3)}>Contact</button>

               </Menu>
            </span>
        </div>

    )
}

export default NavBar