const artData = [
    {
        id: 1,
        name: 'Yugioh',
        imageSrc: '/jada-carter-timetoduel.jpg'
    },
    {
        id: 2,
        name: 'Witch Doctor',
        imageSrc: '/jada-carter-witchdoctor-2.jpg'
    },
    {
        id: 3,
        name: 'Witch Doctor',
        imageSrc: '/jada-carter-witchdoctor-2.jpg'
    },
    {
        id: 4,
        name: 'Witch Doctor',
        imageSrc: '/jada-carter-witchdoctor-2.jpg'
    },
    {
        id: 5,
        name: 'Witch Doctor',
        imageSrc: '/jada-carter-witchdoctor-2.jpg'
    },
];

export default artData