import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home/HomeSection';
import Footer from './components/Footer/Footer'
import { useRef } from 'react';
import './App.css';


function App() {
  let home = useRef();
  let portfolio = useRef();

  return (
    <div className="App gradient-background">
      <NavBar ref0={home} ref1={portfolio}/>
      <Routes>
         
          <Route path="/" element={<Home/>} />
          
          
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
