import './AboutSection.css';
import { useState } from 'react';
function AboutSection( {imageName, title, description, imageLeft})
{

    const [isImageLeft, setImageIsLeft] = useState(imageLeft);


    
    return(
        <div id='aboutSection'>
                {/*left image*/}
                <img  id='image'src={imageName}></img>
                <div id='textSection'>
                    <div id='text'>
                        <h1>{title}</h1>
                        <br></br>
                        <p id='aboutText'>
                            {description}
                        </p>
                    </div>
                </div>
                {/*right image*/}
                <div id={isImageLeft ? 'hiddenSection' : 'imageSection'}>
                    <img  id='image'src={imageName}></img>
                </div>
        </div>
    )
}

export default AboutSection